var swiper = new Swiper(".reviews__slider", {
  pagination: {
    el: ".custom-progressbar",
    type: "progressbar",
    renderProgressbar: function (progressbarFillClass) {
      return '<div class="' + progressbarFillClass + ' custom-progress"></div>';
    },
  },
  navigation: {
    nextEl: ".reviews__nav-btn--next",
    prevEl: ".reviews__nav-btn--prev",
  },
  slidesPerView: 1.1,
  spaceBetween: 15,
  effect: "slide",
  speed: 500,
  autoplay: {
    delay: 3500,
    disableOnInteraction: false,
  },
  breakpoints: {
    // Ширина экрана меньше 768px
    768: {
      slidesPerView: 2,
    },
    // Ширина экрана меньше 992px
    992: {
      slidesPerView: 3,
    },
    // Ширина экрана меньше 1200px
    1200: {
      slidesPerView: 4,
    },
  },
});
