document.addEventListener("DOMContentLoaded", function() {
  const progressBars = document.querySelectorAll('.progress-bar__fill');

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  function animateProgressBar(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const bar = entry.target;
        const percentage = bar.getAttribute('data-percentage');
        bar.style.width = percentage + '%';
        observer.unobserve(bar); // Stop observing once animation is triggered
      }
    });
  }

  const observer = new IntersectionObserver(animateProgressBar, observerOptions);

  progressBars.forEach(bar => {
    observer.observe(bar);
  });
});
